import React, { Fragment, useState, useEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import AuthActions from "./Navbar/AuthActions"
import AuthActionsMobile from "./Navbar/AuthActionsMobile"

import { getSignedInUser } from "../Auth/services/user"
import { handleSignOut } from "../Auth/services/signout"
import styles from "./utils/layout.module.scss"
import useNavbarIcons from "./services/useNavbarIcons"

import doctorIcon from "../../../static/images/icons/doctor.svg"
import drugsIcon from "../../../static/images/icons/drugs.svg"
import healthcareIcon from "../../../static/images/icons/healthcare.svg"

const Navbar = ({ isSignInPage }) => {
  const data = useNavbarIcons()

  const logoLongWhite = data.logoLongWhite.childImageSharp.fixed
  const logoLongGreen = data.logoLongGreen.childImageSharp.fixed
  const logoCircleWhite = data.logoCircleWhite.childImageSharp.fixed

  const [isMenuActive, setMenuActive] = useState(false)
  const [user, setUser] = useState({})
  const [userData, setUserData] = useState({ roles: [] })

  const handleMenuActive = () => setMenuActive(!isMenuActive)

  useEffect(() => {
    const userData = getSignedInUser()?.userData
    setUserData(userData)
    const authUser = getSignedInUser()?.authUser
    setUser(authUser)
  }, [])

  const Logo = () => {
    return (
      <Link to="/">
        <div className={classNames(styles["navbar__brandLogos"])}>
          <Img fixed={logoLongWhite} className="is-hidden-mobile" />
          <Img fixed={logoCircleWhite} className="is-hidden-tablet" />
        </div>
      </Link>
    )
  }

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-light is-primary">
        <div className={classNames("ml-1", styles["navbar__brand"])}>
          <Logo />
          <div className={styles["navbar__burgerContainer"]}>
            <a
              onClick={handleMenuActive}
              aria-label="menu"
              aria-expanded="false"
              className="icon is-clickable"
              role="button"
              onKeyDown={(event) => {
                if (event.key === "Enter") handleMenuActive()
              }}
              tabIndex={0}
            >
              <FontAwesomeIcon icon={isMenuActive ? faTimes : faBars} />
            </a>
          </div>
        </div>

        <div
          className={classNames("navbar-menu", styles["navbar__menu"], {
            "is-active": isMenuActive,
          })}
        >
          <div
            className={classNames(
              "navbar-end is-flex is-flex-direction-column"
            )}
          >
            <a
              onClick={handleMenuActive}
              aria-label="menu"
              aria-expanded="false"
              className="is-clickable is-align-self-flex-end mr-1 mt-1 is-hidden-desktop"
              role="button"
              onKeyDown={(event) => {
                if (event.key === "Enter") handleMenuActive()
              }}
              tabIndex={0}
            >
              <div
                className={classNames(
                  "has-text-white",
                  styles["navbar__closeButton"]
                )}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </a>
            <div className="w-full is-flex is-justify-content-center my-1 is-hidden-desktop">
              <Img fixed={logoLongGreen} />
            </div>
            {!user && (
              <>
                <Link to="/patient/enroll">
                  <div
                    className={classNames(
                      "is-hidden-desktop",
                      styles["navbar__menuItem"]
                    )}
                  >
                    <div
                      className={classNames(
                        styles["navbar__menuImageContainer"]
                      )}
                    >
                      <img
                        src={healthcareIcon}
                        className={classNames(styles["navbar__menuIcon"])}
                        alt="Healthcare Icon"
                      />
                    </div>
                    <p className="has-text-primary is-size-5 m-0 ml-1">
                      Patient Enrollment
                    </p>
                  </div>
                </Link>
                <Link to="/doctor/enroll">
                  <div
                    className={classNames(
                      "is-hidden-desktop",
                      styles["navbar__menuItem"]
                    )}
                  >
                    <div
                      className={classNames(
                        styles["navbar__menuImageContainer"]
                      )}
                    >
                      <img
                        src={doctorIcon}
                        className={classNames(styles["navbar__menuIcon"])}
                        alt="Doctor Icon"
                      />
                    </div>
                    <p className="has-text-primary is-size-5 m-0 ml-1">
                      Doctor Enrollment
                    </p>
                  </div>
                </Link>
              </>
            )}
            <Link to="/epharmacy/order">
              <div
                className={classNames(
                  "is-hidden-desktop",
                  styles["navbar__menuItem"]
                )}
              >
                <div
                  className={classNames(styles["navbar__menuImageContainer"])}
                >
                  <img
                    src={drugsIcon}
                    className={classNames(styles["navbar__menuIcon"])}
                    alt="Drugs Icon"
                  />
                </div>
                <p className="has-text-primary is-size-5 m-0 ml-1">
                  Order Medicines
                </p>
              </div>
            </Link>
            <AuthActionsMobile user={user} />
            <AuthActions
              user={user}
              userData={userData}
              handleSignOut={() => {
                setMenuActive(false)
                handleSignOut({ redirect: true })
              }}
              isSignInPage={isSignInPage}
            />
            <Link to="/help-center">
              <p
                className={classNames(
                  "is-size-6 has-text-dark ml-1 mt-1 is-hidden-desktop",
                  styles["navbar__footerItem"]
                )}
              >
                Help Center
              </p>
            </Link>
            <Link to="/terms-and-conditions">
              <p
                className={classNames(
                  "is-size-6 has-text-dark ml-1 is-hidden-desktop",
                  styles["navbar__footerItem"]
                )}
              >
                Terms and Conditions
              </p>
            </Link>
            <Link to="/privacy-policy">
              <p
                className={classNames(
                  "is-size-6 has-text-dark ml-1 is-hidden-desktop",
                  styles["navbar__footerItem"]
                )}
              >
                Privacy Policy
              </p>
            </Link>
          </div>
        </div>
      </nav>
      <div
        role="button"
        tabIndex={0}
        aria-label="Navbar Background"
        onKeyDown={(event) => {
          if (event.key === "Enter") setMenuActive(false)
        }}
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
