import React, { Fragment, useContext } from "react"
import { Link, navigate } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/layout.module.scss"
import { AuthContext } from "../../../context/AuthContext"

const AuthActions = ({ user, userData, handleSignOut, isSignInPage }) => {
  const { authState } = useContext(AuthContext)
  const isEnrolled = () => !!user && !!userData?.authUid

  if (isEnrolled()) {
    return (
      <Fragment>
        <div className="navbar-item is-hidden-desktop">
          <button
            className="button is-secondary is-fullwidth mt-1 is-centered"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
        <div className="navbar-item has-dropdown is-hoverable is-hidden-touch">
          <span className="navbar-link">
            <FontAwesomeIcon icon={faUser} />
            <span className="ml-1">
              {" "}
              {`${userData?.firstName} ${userData?.lastName}`}
            </span>
          </span>
          <div className="navbar-dropdown">
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"]
              )}
              onClick={() => navigate("/profile")}
            >
              Profile
            </span>
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"]
              )}
              onClick={handleSignOut}
            >
              Sign Out
            </span>
          </div>
        </div>
      </Fragment>
    )
  } else if (!!user && !userData?.authUid) {
    // During enrollment
    return (
      <Fragment>
        <div className="navbar-item">
          <button
            className="button is-primary is-fullwidth mt-1 is-centered is-hidden-desktop"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
        <div className="navbar-item has-dropdown is-hoverable is-hidden-touch">
          <span className="navbar-link">
            <FontAwesomeIcon icon={faUser} />
            <span className="ml-1">{authState?.mobileNumber}</span>
          </span>
          <div className="navbar-dropdown">
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"]
              )}
              onClick={handleSignOut}
            >
              Sign Out
            </span>
          </div>
        </div>
      </Fragment>
    )
  } else {
    // Signed out
    return (
      !isSignInPage && (
        <Fragment>
          <div className="navbar-item">
            <Link
              className="button is-white has-text-primary is-hidden-mobile"
              to="/sign-in"
            >
              Sign In
            </Link>
            <Link
              className="button is-secondary is-fullwidth mt-1 is-centered is-hidden-tablet is-hidden-desktop"
              to="/sign-in"
            >
              Sign In
            </Link>
          </div>
        </Fragment>
      )
    )
  }
}

export default AuthActions
