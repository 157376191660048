import { useStaticQuery, graphql } from "gatsby"

const useFooterIcons = () => {
  const data = useStaticQuery(graphql`
    {
      logoLongGreen: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useFooterIcons
