import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**
 * The classic button, in different colors, sizes, and states
 * @param {string} color string options: primary, success, info, danger, light, dark
 * @param {string} size string options: small, normal, medium, large
 * @param {string} variant string options: outlined, inverted
 * @param {string} type string options: submit, reset
 * @param {boolean} isLoading boolean
 * @param {boolean} isDisabled  boolean
 * @param {boolean} fullwidth  boolean
 * @param {} children
 */

const Button = ({
  color,
  variant,
  size,
  children,
  type,
  isLoading,
  isDisabled,
  isFullwidth,
  className,
  ...props
}) => {
  let isSubmit = type === "submit"

  return (
    <button
      className={classNames(
        `button is-${color} is-${size} is-${variant} ${className}`,
        { 
          "is-fullwidth": isFullwidth,
        },
      )}
      disabled={isDisabled || isLoading}
      type={type || "button"}
      {...props}
    >
      {isSubmit && isLoading && <FontAwesomeIcon icon={faSpinner} className="mr-1" spin={true} />}
      <span>{children}</span>
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default Button
