import { camelizeKeys } from "humps"
import { isBrowser } from "services/general"
import { GATSBY_WEBSITE_URL } from "gatsby-env-variables"

export const hasSignedInUser = () => {
  if (isBrowser()) {
    const USER = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]

    return USER
  }
}

export const getSignedInUser = () => {
  if (isBrowser()) {
    let fauthUser = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]
    let authUser = JSON.parse(sessionStorage.getItem(fauthUser))
    // let authUser = JSON.parse(sessionStorage.getItem(authUser))
    let userData = camelizeKeys(JSON.parse(sessionStorage.getItem("userData")))
    let addresses = JSON.parse(sessionStorage.getItem("addresses"))

    return { authUser, userData, addresses }
  } else return {}
}

export const hasRoles = () => {
  const { userData } = getSignedInUser()
  let hasRoles = false

  if (userData) {
    let activeRole = userData?.roles?.find(
      (role) =>
        role?.subdomain === GATSBY_WEBSITE_URL && role?.status === "active"
    )
    if (activeRole) hasRoles = true
  }

  return hasRoles
}

export const hasVerifiedUser = () => {
  const { userData } = getSignedInUser()

  return !!userData?.verified && userData?.verified === "birthday"
}

export const hasVerifiedName = () => {
  if (isBrowser()) {
    let hasVerifiedName = JSON.parse(sessionStorage.getItem("hasVerifiedName"))
    
    return hasVerifiedName
  }
}