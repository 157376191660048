import React from "react"
import PropTypes from "prop-types"

import styles from "./utils/elements.module.scss"

/**
 * @param {number} size integer, defaults to 2
 */

const Loading = ({ size }) => (
  <div className={styles["loading"]} style={{ fontSize: `${size || 2}rem` }} />
)

Loading.propTypes = {
  size: PropTypes.string,
}

export default Loading
