import { useStaticQuery, graphql } from "gatsby"

const useNavbarIcons = () => {
  const data = useStaticQuery(graphql`
    {
      logoLongWhite: file(relativePath: { eq: "logos/medgrocer-long-white.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoLongGreen: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCircleWhite: file(relativePath: { eq: "logos/medgrocer-square-white.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      doctorIcon: file(relativePath: { eq: "icons/doctor.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      drugsIcon: file(relativePath: { eq: "icons/drugs.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      healthcareIcon: file(relativePath: { eq: "icons/healthcare.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useNavbarIcons
