import React from "react"
import Img from "gatsby-image"

import Container from "layout/Container"
import { Link } from "gatsby"
import sitemap from "./utils/sitemap.json"
import classNames from "classnames"

import useFooterIcons from "./services/useFooterIcons"

const Footer = () => {
  const data = useFooterIcons()
  const logoLongGreen = data.logoLongGreen.childImageSharp.fixed

  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer px-1">
      <Container isCentered mobile={12} tablet={10} desktop={10} fullhd={8}>
        <div className="columns centered">
          <div className="column is-7 pl-0 pr-2">
            <Img fixed={logoLongGreen} />
            <p className="is-size-6 my-1">
              MedGrocer's digitally-enabled corporate healthcare solutions and
              patient programs empower our customers to get their medicines,
              vaccines, and teleconsultations conveniently, cost-effectively,
              and intelligently.
            </p>
            <p className="is-size-6 my-1">
              Every year, we serve over one million Filipinos either directly or
              through partnerships with employers and pharmaceutical companies.
            </p>
            <p className="is-size-6 my-1">
              © {currentYear} · MedGrocer (MG Health Solutions Inc.)
            </p>
          </div>

          {sitemap.map((section, index) => (
            <div
              className={classNames(section.classnames, "column is-3 pl-0")}
              key={index}
            >
              <h5 className="mt-0 has-text-primary">{section.section}</h5>
              {section.links.map((link) => (
                <Link
                  to={`${link.url}`}
                  className="help has-text-secondary is-size-6 underline font-bold"
                  key={link.url}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </Container>
    </footer>
  )
}

export default Footer
