import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import styles from "../utils/layout.module.scss"
// import { AuthContext } from "../../../context/AuthContext"

import profileIcon from "../../../../static/images/icons/user.svg"

const AuthActionsMobile = ({ user }) => {
  if (user)
    return (
      <Link to="/profile">
        <div
          className={classNames(
            "is-hidden-desktop",
            styles["navbar__menuItem"]
          )}
        >
          <div className={classNames(styles["navbar__menuImageContainer"])}>
            <img
              src={profileIcon}
              className={classNames(styles["navbar__menuIcon"])}
            />
          </div>
          <p className="has-text-primary is-size-5 m-0 ml-1">View Profile</p>
        </div>
      </Link>
    )

  return null
}

export default AuthActionsMobile
